.floating-media {
  position: absolute;
  top: 50vh;
  right: 0;
  width: 30px;
  border-radius: 2px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.floating-media > a {
  text-decoration: none;
  margin: 8px 0px;
  color: var(--text);
  font-size: 20px;
}

@media (max-width: 998px) {
  .floating-media {
    display: flex;
  }
}
