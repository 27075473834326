.heading-main {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  font-weight: 200;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 16px;
}

@media (max-width: 998px) {
  .heading-main {
    font-size: 1rem;
  }
}
