.modal-main {
  z-index: 101;
  position: fixed;
  /* background-color: var(--text); */
  /* border: 1px solid #ccc; */
  /* box-shadow: 1px 1px 1px var(--bg); */
  padding: 16px;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

div.modal-backdrop {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  z-index: 100;
  backdrop-filter: blur(7px);
}

/* web */
@media (min-width: 998px) {
  .modal-main > div.modal-body {
    /* width: 500px;
        left: calc(50% - 250px); */
  }
}
