.admin-error {
  color: red;
  font-size: 15px;
}
.admin-main {
  padding: 8rem 40px 40px 40px;
}
.admin-main-floating {
  position: fixed;
  bottom: 0;
  right: 0;
  /* transform: translate(-50%,-50%); */
  z-index: 100;
}

.admin-buttons button {
  text-transform: uppercase;
  outline: 0;
  background: var(--bg);
  width: fit-content;
  border: 1px solid var(--text);
  border-radius: 5px;
  padding: 15px;

  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  margin: 10px;
  float: right;
}

.admin-buttons button:active {
  background: gray;
}
.admin-buttons button:disabled {
  background: gray;
}

.admin-form-div {
  position: relative;
  z-index: 1;
  background: #ffffff;
  border-radius: 10px;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 40px;
  text-align: center;
}
.admin-form-div > .admin-form-note {
  color: var(--bg);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 12px;
}

.admin-form-div label {
  outline: 0;
  background: var(--text);
  width: fit-content;
  font-size: 14px;
  color: var(--bg);
  float: left;
  padding: 5px;
}
.admin-form-div input {
  outline: 0;
  background: var(--text);
  width: 100%;
  border: 1px solid var(--bg);
  border-radius: 5px;
  margin: 0 0 10px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
}
.admin-form-div input:focus {
  background: var(--text);
}

.admin-form-div button {
  text-transform: uppercase;
  outline: 0;
  background: var(--bg);
  width: fit-content;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  margin-right: 10px;
}

.admin-form-div button:active {
  background: gray;
}

.admin-form-checkbox {
  border: 1px solid var(--bg);
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 5px;
}
.admin-form-checkbox > input {
  width: fit-content;
  visibility: hidden;
}

.admin-form-checkbox > label {
  cursor: pointer;
  margin-right: 10px;
}

.admin-form-checkbox > label.admin-form-check-simple {
  background-color: var(--text);
  color: var(--bg);
}

.admin-form-checkbox > label.admin-form-check-highlight {
  background-color: var(--bg);
  color: var(--text);
}
.admin-filename {
  color: var(--bg);
  font-size: x-small;
}

.admin-form-div input.admin-form-greenborder {
  border: 4px solid green;
}

.admin-form-div input.admin-form-blackborder {
  border: 1px solid var(--bg);
}
