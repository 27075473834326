.home-videbanner {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.home-videbanner > #videoBG {
  z-index: -1;
  position: absolute;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home-videbanner > #videoBG-mobile {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home-content {
  margin-top: -70px;
}
@media (min-width: 998px) {
  .home-content {
    margin-top: 0px;
  }
  .ideoBG-div {
    display: block;
  }
  .videoBG-mobile-div {
    display: none;
  }
}
@media (max-width: 998px) {
  .videoBG-div {
    display: none;
  }
  .videoBG-mobile-div {
    display: block;
  }
}
