.list-error {
  color: red;
  font-size: 15px;
}
.list-main {
  width: 100%;
  height: 100px;
  /* border-radius: 5px; */
  /* background-color: var(--text); */
  padding: 10px;
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  color: var(--bg);
  /* border: 2px solid var(--text); */
  opacity: 0.9;
  margin-top: 10px;
}

.list-main > img {
  width: 15%;
  height: 80px;
  /* flex: 1; */
  border-radius: 5px;
}

.list-column {
  display: flex;
  flex-direction: column;
  align-items: initial;
  flex: 4;
}

.list-row {
  width: inherit;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
}

.list-column > span {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
}

.list-row > a {
  color: var(--bg);
}
.list-row > button {
  height: 28px;
  width: 28px;
  border-radius: 100%;
  background-color: var(--bg);
  border: 1px solid var(--bg);
  color: var(--text);
  cursor: pointer;
}
