/* .client-main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.client-img {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 300px;
  width: 500px;
}

.client-img > img {
  height: 50px;
  width: 80px;
  margin: 2px;
  padding: 5px;
  object-fit: contain;
  background-color: var(--text);
  border-radius: 5px;
} */
.client2-main-div{
  width: 1200px;
  margin: auto;
}
.client2-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 50px 50px;
  /* max-height: 400px; */
  overflow: hidden;
/* width:800px */
}

.client2-main > img {
  width: 130px;
  height: 130px;
  object-fit: contain;
}
.client-slide-track {
  display: none;
}

@media screen and (max-width: 1500px) {
  .client2-main > img {
    width: 100px;
    height: 100px;
  }
  .client2-main-div{
    width: 900px;
  }
}
@media screen and (max-width: 998px) {
  .client2-main-div{
    width: 100%;
  }
  .client2-main {
    /* display: none; */
    margin: 0 0 50px;
  }
  .client2-main > img {
    width: 60px;
    height: 60px;
  }
  /* .client-slide > img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  .scrollLeft {
    animation: scroll 45s linear infinite;
  }
  .scrollRight {
    animation: scroll2 45s linear infinite;
  }
  .client-slide-track {
    width: 100%;
    display: flex;
    overflow: hidden;
    gap: 1em;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translatex(-2400%);
    }
  }
  @keyframes scroll2 {
    0% {
      transform: translatex(-2400%);
    }
    100% {
      transform: translatex(0);
    }
  } */
}
