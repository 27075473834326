.pagenotfound-main {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin: 0px;
  height: 70vh;
  width: 100%;
  flex-direction: column;
  padding-top: 8rem;
}
.pagenotfound-main > p {
  font-size: 20px;
  color: var(--bg);
}
.pagenotfound-main > a {
  border-radius: 5px;
  background-color: var(--bg);
  color: var(--text);
  padding: 10px;
  margin: 10px 0px 50px 0px;
}
.pagenotfound-main > p.not-found {
  font-size: xx-large;
  font-weight: 800;
  text-transform: uppercase;
  background-color: var(--text);
  color: var(--bg);
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
}
.tipsiz {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  flex-direction: column;
}
.tipsiz-body {
  display: flex;
  align-items: center;
  margin: 10px;
}
.arm {
  align-self: flex-end;
  width: 50px;
  height: 90px;
  border-top: 3px solid var(--bg);
}
.left-arm {
  border-right: 3px solid var(--bg);
  transform: skew(20deg);
}
.right-arm {
  border-left: 3px solid var(--bg);
  transform: skew(-20deg);
}

.face {
  width: 300px;
  height: 200px;
  border: 3px solid var(--bg);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 15px;
}
.upper-face {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 80px;
  /* margin-left: 80px; */
}
.element {
  color: var(--bg);
  font-size: 60px;
}
.mouth {
  width: 20px;
  height: 10px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border: 3px solid var(--bg);
  border-bottom: 0;
}

@media (max-width: 998px) {
  .pagenotfound-main > p.not-found {
    font-size: x-large;
  }
  .arm {
    width: 30px;
    height: 50px;
  }
  .element {
    font-size: 30px;
  }
  .face {
    width: 250px;
    height: 150px;
  }
}
