.login {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}

.form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  border-radius: 10px;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
}

.form input {
  outline: 0;
  background: var(--text);
  width: 100%;
  border: 1px solid var(--bg);
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.form input:focus {
  background: var(--text);
}

.form button {
  text-transform: uppercase;
  outline: 0;
  background: var(--bg);
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.form button:active {
  background: gray;
}

.form .material-icons {
  font-size: 75px;
  color: var(--bg);
}

.loginError {
  color: red;
}
