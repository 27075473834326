.about-main-desktop {
  padding-top: 9rem;
  padding-bottom: 4rem;
  margin: 0px 12%;
}
.about-services {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: var(--custom_size_para);
  flex-wrap: wrap;
  margin: 10px;
  margin-bottom: 60px;
  width: 100%;
}
.about-services-card-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.about-services-card {
  position: relative;
  margin: 10px;
}
.about-services-card > img {
  height: 120px;
  width: 120px;
  object-fit: contain;
}
.about-services-card > span {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 180px;
  word-wrap: break-word;
  font-weight: 400;
}
.about-services-p {
  padding: 10px;
  width: 400px;
  word-wrap: break-word;
}
.about-about-body > h3{
  font-size: var(--custom_size_head);
}
.about-main-desktop > .about-about {
  min-width: 28rem;
  display: flex;
  margin: 100px 50px;
  align-items: center;
  justify-content: center;
}

.about-about-body {
  padding-right: 10%;
}
.about-about-body > p,
.about-services,
.accordion h1,
.about-main-choose-colorkaar,
.accordion-item .accordion-item-desc,
.accordion-item .accordion-item-title {
  font-size: var(--custom_size_para);
}

.about-main-desktop > .about-about > img {
  width: 60rem;
  height: 30rem;
  object-fit: cover;
}

.about-main-desktop-storyteller {
  font-size: var(--custom_size_head);
  text-align: center;
  text-transform: uppercase;
  padding: 40px 20px;
  /* border: 1px solid var(--text); */
  border-radius: 5px;
}
.about-main-desktop-contact {
  font-size: var(--custom_size_head);
  text-align: center;
  text-transform: uppercase;
  padding: 40px 0px;
margin: 0 auto;}
.about-main-choose-colorkaar {
  max-width: 1200px;
  margin: auto;
  margin-bottom: 50px;
}
.carousel-div{
  margin: 0 auto 50px;
  width: 95%;
}
@media screen and (max-width: 1900px) {
  .about-about-body > p,
  .about-services,
  .accordion h1,
  .about-main-choose-colorkaar,
  .accordion-item .accordion-item-desc,
  .accordion-item .accordion-item-title {
    font-size: var(--custom_size_para);
  }

  .about-main-desktop > .about-about > img {
    width: 40rem;
    height: 20rem;
  }
  .about-main-desktop-storyteller {
    font-size: var(--custom_size_head);
    padding: 40px 0px;
  }
  .about-main-desktop-contact {
    font-size: var(--custom_size_head);
  }
  .about-services-card > img {
    height: 120px;
    width: 120px;
  }
  .about-services-card > span,
  .about-services-p {
    /* width: 230px; */
    width: 272px;
  }
  .about-main-choose-colorkaar {
    max-width: 900px;
  }
}
@media screen and (max-width: 1500px) {
  .about-about-body > p,
  .about-services,
  .accordion h1,
  .about-main-choose-colorkaar,
  .accordion-item .accordion-item-desc,
  .accordion-item .accordion-item-title {
    font-size: var(--custom_size_para);
  }

  .about-main-desktop > .about-about > img {
    width: 30rem;
    height: 15rem;
  }
  .about-main-desktop-storyteller {
    font-size: var(--custom_size_head);
    padding: 40px 0px;
  }
  .about-main-desktop-contact {
    font-size: var(--custom_size_head);
  }
  .about-services-card > img {
    height: 100px;
    width: 100px;
  }
  .about-services-card > span,
  .about-services-p {
    width: 220px;
  }
  .about-main-choose-colorkaar {
    max-width: 600px;
  }
}
@media screen and (max-width: 998px) {
  .about-main-desktop {
    padding-top: 10rem;
    padding-bottom: 4rem;
    margin: 0px 0px;
  }
  .about-main-desktop > .about-about {
    min-width: 10rem;
    display: flex;
    flex-direction: column;
    margin: 0 20px 50px 20px;
    align-items: center;
    justify-content: center;
  }
  .about-about-body {
    padding-right: 0%;
    margin: 0 20px 50px 20px;
    text-align: center;
  }
  .about-about-body > p,
  .about-services,
  .accordion h1,
  .about-main-choose-colorkaar,
  .accordion-item .accordion-item-desc,
  .accordion-item .accordion-item-title {
    font-size: var(--custom_size_para);
    line-height: 1rem;
  }
  .about-main-desktop > .about-about > img {
    width: 20rem;
    height: 10rem;
  }
  .about-main-desktop-storyteller {
    font-size: var(--custom_size_head);
    padding: 20px 10px;
    margin: 0px 20px 10px 20px;
  }
  .about-main-desktop-contact {
    font-size: var(--custom_size_head);
    padding: 20px 0px;
  }
  .about-services {
    justify-content: center;
    margin: 0px;
    margin-bottom: 60px;
  }
  /* .about-services-card {
    margin: 0px;
  }
  .about-services-card {
    position: relative;
    margin: 10px;
  } */
  .about-services-card > img {
    height: 70px;
    width: 70px;
  }
  .about-services-card > span,
  .about-services-p {
    width: 195px;
  }
  .about-main-choose-colorkaar {
    max-width: auto;
    margin: 0 40px 50px;
   }
  .carousel-div{
    margin: 0 10px 50px;
    width: auto;
  }
  .about-main-desktop-contact {
    max-width: 300px;}
}

/* accordion */
/* .accordion {
  display: flex;
  flex-direction: column;
  max-width: 991px;
  min-width: 320px;
  margin: auto;
  padding: 0 50px;
  margin-bottom: 50px;
}
.accordion h1 {
  text-align: center;
}
.accordion-item {
  margin-top: 16px;
  border: 1px solid #fcfcfc;
  border-radius: 6px;
  background: #000000;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.accordion-item .accordion-item-title {
  position: relative;
  margin: 0;
  display: flex;
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 14px 20px;
  box-sizing: border-box;
  align-items: center;
}
.accordion-item .accordion-item-desc {
  display: none;
  line-height: 22px;
  font-weight: 300;
  color: #ffffff;
  border-top: 1px dashed #ddd;
  padding: 10px 20px 20px;
  box-sizing: border-box;
}
.accordion-item input[type="checkbox"] {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}
.accordion-item input[type="checkbox"]:checked ~ .accordion-item-desc {
  display: block;
}
.accordion-item
  input[type="checkbox"]:checked
  ~ .accordion-item-title
  .icon:after {
  content: "-";
  font-size: 20px;
}
.accordion-item input[type="checkbox"] ~ .accordion-item-title .icon:after {
  content: "+";
  font-size: 20px;
}
.accordion-item:first-child {
  margin-top: 0;
}
.accordion-item .icon {
  margin-left: 14px;
}

@media screen and (max-width: 767px) {
  .accordion {
    padding: 0 16px;
  }
} */
