@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
:root {
  --text: white;
  --bg: black;
  --error: #ff4a4a;
  --font: "Poppins", sans-serif;
  --custom_size_para: 1.4rem;
  --custom_size_head: 2rem;
}
@media screen and (max-width: 1900px) {
  :root {
    --custom_size_para: 1.2rem;
    --custom_size_head: 1.5rem;
  }
}
@media screen and (max-width: 1500px) {
  :root {
    --custom_size_para: 0.9rem;
    --custom_size_head: 1rem;
    
  }
}@media screen and (max-width: 1300px) {
  :root {
    --custom_size_para: 0.7rem;
    --custom_size_head: 1rem;
  }
}
@media screen and (max-width: 998px) {
  :root {
    --custom_size_para: 0.65rem;
    --custom_size_head: 0.8rem;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 2px;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
/* base styles & title */
body {
  color: var(--text);
  background-color: var(--bg);
  max-width: 100%;
  overflow-x: hidden;
  font-family: var(--font);
}
textarea {
  font-family: var(--font);
}
button {
  cursor: pointer;
}
