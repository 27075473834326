.reel-main{
    padding-top: 8rem;
    height: 95vh;
}

@media (max-width: 998px) {
    .reel-main{
        height: 85vh;
    }

}