.dropbtn {
  color: var(--text);
  font-size: 16px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.dots-icons li {
  background: none repeat scroll 0 0 var(--text);
  height: 8px;
  width: 8px;
  line-height: 0;
  list-style: none outside none;
  margin-right: 15px;
  margin-top: 3px;
  vertical-align: top;
  border-radius: 50%;
  pointer-events: none;
}

.showLeft {
  text-shadow: var(--text) !important;
  color: var(--text) !important;
  padding: 10px;
}

@media (max-width: 998px) {
  .dots-main {
    margin-top: -80px;
  }
}
