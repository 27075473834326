.nav-main {
  width: 100%;
  height: 10rem;
  display: grid;
  grid-template-columns: 5rem 1fr 2fr 1fr 5rem;
  position: absolute;
}

.nav-logo {
  display: grid;
  grid-column: 2/3;
  justify-content: start;
  align-items: center;
  z-index: 99;
}

.nav-logo > a > img {
  width: 130px;
  height: 120px;
}

.nav-menu-links {
  grid-column: 3/4;
  z-index: 99;
}

.nav-menu-links > ul > li {
  height: 10rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.nav-menu-links > ul > li > a {
  font-size: var(--custom_size_para);
  color: var(--text);
  text-transform: uppercase;
  font-weight: 600;
}
.nav-menu-links > ul > li > a.active {
  text-decoration: underline;
}

.nav-socialmedia {
  grid-column: 4/5;
  z-index: 99;
}

.nav-socialmedia > ul {
  height: 10rem;
  display: grid;
  grid-template-columns: 3fr repeat(3, 1fr);
  align-items: center;
  justify-content: flex-end;
}

.nav-socialmedia > ul > li {
  text-align: right;
}

.nav-socialmedia > ul > li:first-child {
  grid-column: 2/3;
}
.nav-socialmedia > ul > li > a {
  font-size: var(--custom_size_para);
  color: var(--text);
  text-transform: uppercase;
}
/* .nav-socialmedia > ul > li > a > svg{
  font-size:1.25rem
} */

.nav-hamburgermenu {
  display: none;
}

/* media query */
/* 1706 */
@media (max-width: 1080px) {

  .nav-main {
    height: 8rem;
    grid-template-columns: 2rem 1fr 2fr 1fr 2rem;
  }
  .nav-logo,
  .nav-menu-links > ul > li,
  .nav-socialmedia > ul {
    height: 8rem;
  }
}

@media (max-width: 998px) {
  .nav-logo > a > img {
    width: 75px;
    height: 70px;
  }
  .backdrop {
    top: 0;
    position: fixed;
    height: 100%;  
    width: 100%;
    background-color: var(--text);
    z-index: 98;
    
  }
  .nav-main {
    height: 7rem;
    grid-template-columns: 2rem 1fr 2fr 1fr 2rem;
  
  }
  .nav-mobile-logo {
    filter: invert(100%);
  }
  .nav-menu-links,
  .nav-socialmedia {
    display: none;
  }
  .nav-hamburgermenu {
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: right;
    font-size: xx-large;
    grid-column: 4/5;
    color: var(--text);
    z-index: 99;
  }
  .nav-mobile-menu-links {
    position: fixed;
    top: 10rem;
    width: 100%;
    z-index: 99;
    background-color: var(--text);
  }
  .nav-mobile-menu-links > ul > li {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .nav-mobile-menu-links > ul > li > a {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: var(--bg);
    font-size: 2rem;
    font-weight: 600;
  }
  .nav-mobile-menu-links > ul > li > a.active {
    text-decoration: underline;
  }

  .nav-mobile-socialmedia {
    position: fixed;
    /* bottom: 5rem; */
    top: 39rem;
    width: 100%;
    z-index: 99;
    background-color: var(--text);
  }
  .nav-mobile-socialmedia > ul {
    display: grid;
    grid-template-columns: 2rem 1fr 1fr 1fr 2rem;
  }
  .nav-mobile-socialmedia > ul > li {
    text-align: center;
  }
  .nav-mobile-socialmedia > ul > li > a {
    color: var(--bg);
    font-size: 1.5rem;
  }
  .nav-mobile-socialmedia > ul > li:first-child {
    grid-column: 2/3;
  }
}
