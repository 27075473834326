.footer-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  padding: 0px 20px 0px 20px;
}
.footer-left-mobile {
  display: none;
}
.footer-left {
  display: flex;
  align-items: center;

  width: 400px;
}
.footer-left > .footer-left-text {
  text-transform: uppercase;
}
.footer-left > .footer-left-logo > img {
  width: 55px;
  height: 50px;
  margin: 20px;
}

.footer-center {
  width: 400px;
}
.footer-center > ul {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.footer-center > ul > li > a {
  color: var(--text);
  text-decoration: none;
}
.footer-right {
  /* padding: 20px 20px 20px 0px; */
  width: 400px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}
.footer-right > div > a {
  /* padding: 8px; */
}
.footer-right > div > a {
  color: var(--text);
  text-decoration: none;
}
.footer-left-text-credits {
  display: none;
}
.footer-left-text-credits > a {
  color: var(--text);
  text-decoration: none;
}
@media screen and (max-width: 1500px) {
  .footer-main {
    font-size: 0.5rem;
  }
  .footer-left > .footer-left-logo > img {
    width: 45px;
    height: 40px;
  }
  .footer-center > ul > li > a > svg {
    height: 14px;
    width: 14px;
  }
  .footer-center {
    width: 300px;
  }
}

@media screen and (max-width: 998px) {
  .footer-main {
    font-size: 0.45rem;
    flex-direction: column-reverse;
  }
  .footer-left-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }
  .footer-left-mobile-text {
    text-align: center;
  }
  .footer-left-mobile > .footer-left-mobile-logo > img {
    width: 45px;
    height: 40px;
    margin: 20px;
  }
  .footer-left-mobile > .footer-left-mobile-social > ul {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .footer-left-mobile > .footer-left-mobile-social > ul > li {
    margin: 16px;
  }
  /* .footer-left-mobile-text-credits {
    display: block;
    width: 100px;
    padding-top: 8px;
    text-transform: uppercase;
  }
  .footer-left-mobile-text-credits > .footer-center {
    display: block;
    width: 100px;
  } */
  .footer-left {
    display: none;
    width: 400px;
    /* width: auto; */
  }
  .footer-center {
    display: none;
  }
  .footer-right {
    display: none;
  }

  .footer-center > ul > li > a > svg {
    height: 8px;
    width: 8px;
  }
  .footer-center > ul {
    /* display: flex;
    align-items: center; */
    justify-content: space-between;
  }
}
/* .footer-main {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 300;
  font-size: 18px;
}

.footer-left > a {
  color: var(--text);
  outline: none;
}

.footer-right > ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1500px) {
  .footer-main {
    font-size: 12px;
  }
}

@media screen and (max-width: 998px) {
  .footer-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: 10px;
  }
} */
