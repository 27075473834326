.simple-photo-grid-wrapper {
  padding: 10px;
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 250px;
  grid-auto-flow: dense;
}

.simple-photo-wide {
  grid-column: span 2;
}

.simple-photo-wide > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

@media (max-width: 998px) {
  .simple-photo-wide {
    grid-column: span 1;
  }
}
