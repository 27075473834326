.contact-main {
  padding-top: 8rem;
  margin: 0px 14%;
  overflow: hidden;
}
.contact-map {
  margin: 50px 20px;
}
.contact-map > a {
  margin-bottom: 20px;
}
.contact-map > a > img {
  width: 100%;
  height: auto;
  cursor: pointer;
}
.contact-about {
  min-width: 28rem;
  display: flex;
  flex-direction: row;
  margin: 50px 50px;
  align-items: flex-start;
  justify-content: center;
  /* height: 75vh; */
}
.contact-about > .contact-about-form,
.contact-about > .contact-about-instagram,
.contact-about-mobile > .contact-about-instagram-mobile {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.contact-about-mobile {
  display: none;
}
.contact-about > .contact-about-form > div.contact-about-input-flex {
  display: flex;
  align-items: center;
}
.contact-about > .contact-about-form > div.contact-about-input,
.contact-about
  > .contact-about-form
  > div.contact-about-input-flex
  > div.contact-about-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 50px;
}
.contact-about > .contact-about-form > div.contact-about-input > label,
.contact-about > .contact-about-form > div.contact-about-input > input,
.contact-about
  > .contact-about-form
  > div.contact-about-input-flex
  > div.contact-about-input
  > input,
.contact-about > .contact-about-instagram,
.contact-about-mobile > .contact-about-instagram-mobile,
.contact-about-form > div.contact-about-button > button,
.contact-about-withsocial,
.contact-map > a > span,
.contact-about > .contact-about-instagram > button,
.contact-about-mobile > .contact-about-instagram-mobile > button {
  font-size: var(--custom_size_para);
  color: white;
}
.contact-about > .contact-about-form > div.contact-about-input > input,
.contact-about
  > .contact-about-form
  > div.contact-about-input-flex
  > div.contact-about-input
  > input {
  border: 0;
  background-color: var(--bg);
  border-bottom: 2px solid var(--text);
  height: 50px;
  width: 80%;
  margin: 6px 6px 6px 0px;
  color: var(--text);
  outline: 0;
  font-family: var(--font);
}
.contact-about
  > .contact-about-form
  > div.contact-about-input
  > input.inputerror,
.contact-about
  > .contact-about-form
  > div.contact-about-input-flex
  > div.contact-about-input
  > input.inputerror,
.contact-about
  > .contact-about-form
  > div.contact-about-input
  > textarea.inputerror {
  border-bottom: 2px solid var(--error);
}
.contact-about > .contact-about-form > div.contact-about-input > textarea {
  border: 0;
  background-color: var(--bg);
  border-bottom: 2px solid var(--text);
  height: 80px;
  width: 80%;
  margin: 6px 6px 6px 0px;
  color: var(--text);
  outline: 0;
  font-family: var(--font);
  font-size: var(--custom_size_para);
}

.contact-about > .contact-about-instagram > button,
.contact-about-mobile > .contact-about-instagram-mobile > button {
  height: 50px;
  letter-spacing: 0.01em;
  line-height: 1;
  margin-top: 25px;
  padding: 0 20px;
  position: relative;
  width: 20%;
  font-weight: 600;
  border: 0;
  color: var(--bg);
}
.contact-about
  > .contact-about-form
  > div.contact-about-input
  > input::placeholder,
.contact-about
  > .contact-about-form
  > div.contact-about-input
  > textarea::placeholder,
.contact-about
  > .contact-about-form
  > div.contact-about-input-flex
  > div.contact-about-input
  > input::placeholder {
  color: var(--text);
}

.contact-about > .contact-about-instagram,
.contact-about-mobile > .contact-about-instagram-mobile {
  align-items: center;
  justify-content: center;

  text-align: center;
}

.contact-about > .contact-about-instagram > div,
.contact-about-mobile > .contact-about-instagram-mobile > div {
  display: block;

  max-width: 460px;
}
.contact-about > .contact-about-instagram > div > .contact-about-instagram-img,
.contact-about-mobile
  > .contact-about-instagram-mobile
  > div
  > .contact-about-instagram-img {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.contact-about-form > div.contact-about-button > span {
  margin-left: 12px;
}
.contact-about-form > div.contact-about-button > button {
  height: 50px;
  letter-spacing: 0.01em;
  line-height: 1;
  margin-top: 25px;
  padding: 0 20px;
  position: relative;
  width: 20%;
  font-weight: 600;
  border: 0;
  color: var(--bg);
}

.contact-about
  > .contact-about-instagram
  > div
  > .contact-about-instagram-img
  > div
  > img,
.contact-about-mobile
  > .contact-about-instagram-mobile
  > div
  > .contact-about-instagram-img
  > div
  > img {
  max-height: 100%;
  width: 150px;
  object-fit: cover;
  vertical-align: bottom;
  padding: 6px;
}
.contact-about-withsocial {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact-about-withsocial > a {
  color: var(--text);
  text-decoration: none;
  margin-top: 2px;
  display: block;
}
.contact-about-withsocial > a:hover {
  color: var(--text);
  text-decoration: underline;
}
.contact-about-withsocial > a > svg {
  margin-right: 4px;
  /* padding-top: 4px; */
}

.contact-socialmedia {
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px; */
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.contact-socialmedia-card {
  height: 50px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  margin: 10px;
}
.contact-socialmedia-card > a {
  color: var(--text);
  display: inline-block;
  font-size: 35px;
  font-weight: 500;
  margin-top: 5px;
  width: 100%;
  text-align: center;
}
.contact-about-form-success {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-about-form-success > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-top: 100px;
  margin-bottom: 50px;
}
.contact-about-form-success > div > svg {
  border-radius: 100%;
  padding: 5px;
  border: 2px solid white;
  margin-bottom: 6px;
}
@media screen and (max-width: 1900px) {
  .contact-about {
    /* height: 70vh; */
  }
  .contact-about-withsocial {
    width: 70%;
  }
  .contact-about
    > .contact-about-instagram
    > div
    > .contact-about-instagram-img
    > div
    > img,
  .contact-about-mobile
    > .contact-about-instagram-mobile
    > div
    > .contact-about-instagram-img
    > div
    > img {
    width: 130px;
  }
  .contact-about-form > div.contact-about-button > button {
    width: 30%;
  }
  .contact-about > .contact-about-form > div.contact-about-input > label,
  .contact-about > .contact-about-form > div.contact-about-input > input,
  .contact-about
    > .contact-about-form
    > div.contact-about-input-flex
    > div.contact-about-input
    > input,
  .contact-about > .contact-about-instagram,
  .contact-map > a > span,
  .contact-about-mobile > .contact-about-instagram-mobile,
  .contact-about-form > div.contact-about-button > button,
  .contact-about-withsocial,
  .contact-about > .contact-about-instagram > button,
  .contact-about-mobile > .contact-about-instagram-mobile > button {
    font-size: var(--custom_size_para);
  }
  .contact-about > .contact-about-form > div.contact-about-input > textarea {
    font-size: var(--custom_size_para);
  }
}
@media screen and (max-width: 1500px) {
  .contact-about {
    /* height: 60vh; */
  }
  .contact-about-withsocial {
    width: 80%;
  }
  .contact-about
    > .contact-about-instagram
    > div
    > .contact-about-instagram-img
    > div
    > img,
  .contact-about-mobile
    > .contact-about-instagram-mobile
    > div
    > .contact-about-instagram-img
    > div
    > img {
    width: 120px;
  }
  .contact-about-form > div.contact-about-button > button {
    width: 40%;
  }
  .contact-about > .contact-about-form > div.contact-about-input > label,
  .contact-about > .contact-about-form > div.contact-about-input > input,
  .contact-about
    > .contact-about-form
    > div.contact-about-input-flex
    > div.contact-about-input
    > input,
  .contact-about > .contact-about-instagram,
  .contact-about-mobile > .contact-about-instagram-mobile,
  .contact-about-form > div.contact-about-button > button,
  .contact-about-withsocial,
  .contact-map > a > span,
  .contact-about > .contact-about-instagram > button,
  .contact-about-mobile > .contact-about-instagram-mobile > button {
    font-size: var(--custom_size_para);
  }
  .contact-about > .contact-about-form > div.contact-about-input > textarea {
    font-size: var(--custom_size_para);
  }
}
@media screen and (max-width: 1300px) {
  .contact-about {
    /* height: 70vh; */
  }
  .contact-about > .contact-about-form > div.contact-about-input,
  .contact-about
    > .contact-about-form
    > div.contact-about-input-flex
    > div.contact-about-input {
    margin-top: 16px;
  }
  .contact-about > .contact-about-form > div.contact-about-input > label,
  .contact-about > .contact-about-form > div.contact-about-input > input,
  .contact-about
    > .contact-about-form
    > div.contact-about-input-flex
    > div.contact-about-input
    > input,
  .contact-about > .contact-about-instagram,
  .contact-about-mobile > .contact-about-instagram-mobile,
  .contact-about-withsocial,
  .contact-map > a > span,
  .contact-about > .contact-about-form > div.contact-about-input > textarea {
    font-size: var(--custom_size_para);
  }
  .contact-about > .contact-about-instagram > button,
  .contact-about-mobile > .contact-about-instagram-mobile > button,
  .contact-about-form > div.contact-about-button > button {
    font-size: var(--custom_size_para);
  }
  .contact-about > .contact-about-instagram > button,
  .contact-about.mobile > .contact-about-instagram.mobile > button {
    width: 25%;
  }
}
@media screen and (max-width: 998px) {
  .contact-main {
    margin: 0px 12px;
  }
  .contact-about-mobile {
    display: flex;
  }
  /* .contact-about {
    flex-direction: column;
  } */
  .contact-about-form > div.contact-about-button > button {
    width: 50%;
  }

  .contact-about > .contact-about-instagram {
    display: none;
  }
  .contact-socialmedia-card {
    min-width: 30px;
    height: 40px;
  }
  .contact-socialmedia-card > a {
    font-size: 25px;
  }
  .contact-about > .contact-about-form > div.contact-about-input > input,
  .contact-about
    > .contact-about-form
    > div.contact-about-input-flex
    > div.contact-about-input
    > input {
    width: 55%;
  }
  .contact-about > .contact-about-form > div.contact-about-input > textarea {
    width: 55%;
  }
  .contact-about
    > .contact-about-instagram
    > div
    > .contact-about-instagram-img
    > div
    > img,
  .contact-about-mobile
    > .contact-about-instagram-mobile
    > div
    > .contact-about-instagram-img
    > div
    > img {
    width: 100px;
  }
  .contact-about-mobile > .contact-about-instagram-mobile > button {
    height: 35px;
    margin: 25px 0px;
    width: 25%;
  }
  .contact-map {
    margin: 0px 50px 50px;
  }
}
