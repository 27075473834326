/* .home-videbanner {
  overflow-x: hidden;
} */
.videoSection-main {
  margin: 20px 20px 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100vw; */
  /* height: 70vh; */
}

.videoSection-main-mobile {
  display: none;
}

@media (max-width: 998px) {
  .videoSection-main-mobile {
    margin: 0px 20px 0px 20px;
    /* width: 90vw;
    height: 50vh; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .videoSection-main {
    display: none;
  }
}

/* @media (min-width: 998px) {
  .ideoBG-div {
    display: block;
  }
  .videoBG-mobile-div {
    display: none;
  }
}
@media (max-width: 998px) {
  .videoBG-div {
    display: none;
  }
  .videoBG-mobile-div {
    display: block;
  }
} */
