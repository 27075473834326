.galleryfilter-mail {
  padding: 50px;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.galleryfilter-mail > ul {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px var(--bg);
  padding: 25px;
}
.galleryfilter-mail > ul > li {
  margin: 0 20px;
  cursor: pointer;
}
.galleryfilter-mail-mobile {
  display: none;
}

@media (max-width: 998px) {
  .galleryfilter-mail {
    display: none;
  }
  .galleryfilter-mail-mobile {
    display: flex;
  }
  .galleryfilter-mail-mobile > .floating-filter {
    position: fixed;
    bottom: 8%;
    right: 10%;
    z-index: 97;
    background-color: var(--bg);
    padding: 16px 16px 4px 16px;
    border-radius: 100%;
    box-shadow: 1px 1px 1px var(--text);
  }
  .galleryfilter-mail-mobile > ul {
    position: fixed;
    bottom: 11%;
    right: 11%;
    z-index: 96;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: fit-content;
    background-color: var(--bg);
    /* border: 1px solid var(--bg); */
    /* box-shadow: 1px 1px 1px var(--text); */
    padding: 25px;
  }
  .galleryfilter-mail-mobile > ul > li {
    margin: 12px 0;
    cursor: pointer;
  }
}
