.down-arrow {
    position: absolute;
    top: calc(100vh - 70px);
    left: calc(50% - 14px);
    width: 0;
    border-radius: 2px;
    animation: jumpInfinite 1.5s infinite;
  }
  
  .down-arrow:after {
    content: " ";
    position: absolute;
    top: 12px;
    left: -10px;
    width: 16px;
    height: 16px;
    border-bottom: 4px solid;
    border-right: 4px solid;
    border-radius: 4px;
    transform: rotateZ(45deg);
  }
  
@media (max-width: 998px) {
  .down-arrow {
    /* top: calc(100vh - 90px); */
    top: calc(100vh - 160px);
    left: calc(50% - 7px);
  }
}
  @keyframes jumpInfinite {
    0% {
      margin-top: 0;
    }
    50% {
      margin-top: 20px;
    }
    100% {
      margin-top: 0;
    }
  }
  