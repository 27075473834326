.spinner-main{
  /* height: 450px; */
  height: 80vh;
}

.loader:empty {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  background-image: url(../assets/img/ColorkaarLogo.webp);
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: 100px 100px;
  -moz-animation: blink normal 2s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 2s infinite ease-in-out; /* IE */
  animation: blink normal 2s infinite ease-in-out;
  z-index: 90;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
