:root {
  --dark: #373349;
  --gap: 15px;
  --width: 250px;
  --height: 160px;
}
@media screen and (max-width: 1800px) and (min-width: 998px) {
  :root {
    --width: 130px;
    --height: 80px;
  }
}
@media screen and (min-width: 1800px) {
  :root {
    --width: 210px;
    --height: 146px;
  }
}
.container {
  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: repeat(auto-fit, minmax(var(--width), 1fr));
  grid-auto-rows: var(--height);
  grid-auto-flow: dense;
  padding: 50px;
  position: relative;
}

/* grid-template-columns: repeat(auto-fit, minmax(var(--width)));
grid-auto-rows: calc(var(--height) / 1.5);
padding: 10px;
grid-gap: calc(var(--gap) / 2); */
.container > div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  overflow: hidden;
}

.container > div::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translateY(100%);
  transition-duration: 0.75s;
}

.container > div > * {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition-duration: 1s;
}
.fit {
  grid-column: span 1;
  grid-row: span 1;
  display: none !important;
}
.horizontal {
  grid-column: span 2;
  grid-row: span 2;
}

.big {
  grid-column: span 4;
  grid-row: span 3;
}
.photo-grid-span {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.photo-grid-span > span {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.3);
  color: var(--text);
  width: 100%;
  height: 100%;
  display: flex;
  /* align-items: center; */
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 10px;
  text-align: center;
}
.photo-grid-span > span > p {
  opacity: 0.8;
}

@media screen and (max-width: 998px) {
  .container {
    grid-template-columns: repeat(auto-fit, minmax(var(--width)));
    /* grid-auto-rows: var(--height); */
    grid-auto-rows: calc(var(--height) / 1.5);
    padding: 10px;
    grid-gap: calc(var(--gap) / 2);
  }
  .big {
    grid-column: span 4;
    grid-row: span 2;
  }
  .horizontal {
    /* grid-column: span 2; */
    /* grid-column: span 4;
    grid-row: span 1; */
    grid-column: span 2;
    grid-row: span 1;
  }
  .fit {
    grid-column: span 4;
    grid-row: span 1;
    display: flex !important;
    visibility: hidden !important;
  }
  .photo-grid-span > span {
    padding-bottom: 5px;
    font-size: 8px;
  }
}
